import Singleton, { ISingleton } from "../misc/SingletonDecorator";
import { loadData } from './loadData';
import React from 'react';
import { EventEmitter } from 'events';
import { BehaviorSubject } from 'rxjs';

export type ImageItem_t = {
    src: string;
    description: string;
};

export type HobbyItem_t = {
    name: string;
    description: string;
    link?: string;
    images?: ImageItem_t[];
};

export type ExperienceItem_t = {
    logo: string;
    title: string;
    company: string;
    description: string;
    from: string;
    to: string;
};

export type EducationItem_t = {
    icon: string;
    title: string;
    institution: string;
    description: string;
    from: string;
    to: string;
    link: string;
};

export type CertificationItem_t = {
    icon: string;
    title: string;
    organization: string;
    description: string;
    from: string;
    to: string;
    link: string;
};

export type SkillsItem_t = {
    icon: string;
    title: string;
    level: string;
};

export type ProjectsItem_t = {
    icon: string;
    title: string;
    description: string;
    link: string;
};

export type ContactInfo_t = {
    email: string;
    phone: string;
    linkedin: string;
};

export type Information_t = {
    experience: ExperienceItem_t[];
    education: EducationItem_t[];
    certifications: CertificationItem_t[];
    skills: SkillsItem_t[];
    projects: ProjectsItem_t[];
    basicInfo: BasicInfo_t;
    professionalSummary: ProfessionalSummary_t;
    personalInterests: PersonalInterests_t;
    additionalInfo: AdditionalInfo_t;
    hobbies: HobbyItem_t[];
    contactInfo: ContactInfo_t; // Add this line
}


export type LangSplitInformation_t = {
    en: Information_t;
    cs: Information_t;
}

// Define new types for the added fields
export type BasicInfo_t = {
    name: string;
    pronouns: string;
    dateOfBirth: string;
    location: string;
};

export type ProfessionalSummary_t = {
    role: string;
    skills: string[];
    advocacy: string;
    interests: string[];
    summaryText: string; // Add this line
};

export type PersonalInterests_t = {
    gaming: {
        genre: string;
        types: string;
    };
    chess: {
        note: string;
    };
    otherHobbies: string;
    homeLife: {
        note: string;
    };
};

export type AdditionalInfo_t = {
    philosophy: string;
};

export const LanguageContext = React.createContext({
    language: "en",
});

@Singleton
export default class StateManager implements ISingleton {
    private _isLoading = true;
    private information: LangSplitInformation_t | null = null;
    private currentLanguage = new BehaviorSubject("en");
    public eventEmitter: EventEmitter = new EventEmitter();

    private loadData = loadData.bind(this);

    public static GetInstance(): StateManager {
        throw new Error("Method not implemented.");
    }

    public async switchLanguage() {
        const language = this.currentLanguage.getValue();

        this.currentLanguage.next(language === "en" ? "cs" : "en");
    }

    public getLanguage(): BehaviorSubject<string> {
        return this.currentLanguage;
    }

    public setInformation(information: LangSplitInformation_t) {
        this.information = information;
        this._isLoading = false;
    }

    public getInformation(): Information_t | null {
        if (this.isLoading()) return null;

        return this.information?.[this.currentLanguage.getValue() ?? "en"];
    }
    public static parseToInformation(data: {
        personalInfoData: PersonalInfo_t;
        experienceData: ExperienceData_t;
        edCertData: EducationCertificationData_t;
        hobbiesData: HobbyData_t;
    }): LangSplitInformation_t {
        let en_Experience: ExperienceItem_t[] = [];
        let en_Education: EducationItem_t[] = [];
        let en_Certification: CertificationItem_t[] = [];
        let en_Skills: SkillsItem_t[] = [];
        let en_Projects: ProjectsItem_t[] = [];
        let en_BasicInfo: BasicInfo_t = data.personalInfoData.en.personalDescription.basicInfo;
        let en_PersonalInterests: PersonalInterests_t = data.personalInfoData.en.personalDescription.personalInterests;
        let en_AdditionalInfo: AdditionalInfo_t = data.personalInfoData.en.personalDescription.additionalInfo;

        let cs_Experience: ExperienceItem_t[] = [];
        let cs_Education: EducationItem_t[] = [];
        let cs_Certification: CertificationItem_t[] = [];
        let cs_Skills: SkillsItem_t[] = [];
        let cs_Projects: ProjectsItem_t[] = [];
        let cs_BasicInfo: BasicInfo_t = data.personalInfoData.cs.personalDescription.basicInfo;
        let cs_PersonalInterests: PersonalInterests_t = data.personalInfoData.cs.personalDescription.personalInterests;
        let cs_AdditionalInfo: AdditionalInfo_t = data.personalInfoData.cs.personalDescription.additionalInfo;


        let cs_ProfessionalSummary: ProfessionalSummary_t = {
            ...data.personalInfoData.cs.personalDescription.professionalSummary,
            skills: data.personalInfoData.cs.personalDescription.professionalSummary.skills.split(','),
            interests: data.personalInfoData.cs.personalDescription.professionalSummary.interests.split(','),
            summaryText: data.personalInfoData.cs.personalDescription.professionalSummary.summaryText // Add this line
        };

        let en_ProfessionalSummary: ProfessionalSummary_t = {
            ...data.personalInfoData.en.personalDescription.professionalSummary,
            skills: data.personalInfoData.en.personalDescription.professionalSummary.skills.split(','),
            interests: data.personalInfoData.en.personalDescription.professionalSummary.interests.split(','),
            summaryText: data.personalInfoData.en.personalDescription.professionalSummary.summaryText // Add this line
        };

        data.experienceData.en.experience.forEach((item) => {
            en_Experience.push({
                logo: item.logo,
                title: item.title,
                company: item.company,
                description: item.description,
                from: item.from,
                to: item.to
            });
        });

        data.experienceData.cs.experience.forEach((item) => {
            cs_Experience.push({
                logo: item.logo,
                title: item.title,
                company: item.company,
                description: item.description,
                from: item.from,
                to: item.to
            });
        });

        data.edCertData.en.education.forEach((item) => {
            en_Education.push({
                icon: item.icon,
                title: item.title,
                institution: item.institution,
                description: item.description,
                from: item.from,
                to: item.to,
                link: item.link
            });
        });

        data.edCertData.cs.education.forEach((item) => {
            cs_Education.push({
                icon: item.icon,
                title: item.title,
                institution: item.institution,
                description: item.description,
                from: item.from,
                to: item.to,
                link: item.link
            });
        });


        data.edCertData.en.certifications.forEach((item) => {
            en_Certification.push({
                icon: item.icon,
                title: item.title,
                organization: item.organization,
                description: item.description,
                from: item.from,
                to: item.to,
                link: item.link
            });
        });

        data.edCertData.cs.certifications.forEach((item) => {
            cs_Certification.push({
                icon: item.icon,
                title: item.title,
                organization: item.organization,
                description: item.description,
                from: item.from,
                to: item.to,
                link: item.link
            });
        });

        data.personalInfoData.en.personalDescription.professionalSummary.skills.split(',').forEach((item) => {
            en_Skills.push({
                icon: "",
                title: item,
                level: ""
            });
        });

        data.personalInfoData.cs.personalDescription.professionalSummary.skills.split(',').forEach((item) => {
            cs_Skills.push({
                icon: "",
                title: item,
                level: ""
            });
        });

        data.personalInfoData.en.personalDescription.professionalSummary.interests.split(',').forEach((item) => {
            en_Projects.push({
                icon: "",
                title: item,
                description: "",
                link: ""
            });
        });

        data.personalInfoData.cs.personalDescription.professionalSummary.interests.split(',').forEach((item) => {
            cs_Projects.push({
                icon: "",
                title: item,
                description: "",
                link: ""
            });
        });

        let en_Hobbies = data.hobbiesData.en.map(hobby => ({
            name: hobby.name,
            description: hobby.description,
            link: hobby.link,
            images: hobby.images
        }));

        let cs_Hobbies = data.hobbiesData.cs.map(hobby => ({
            name: hobby.name,
            description: hobby.description,
            link: hobby.link,
            images: hobby.images
        }));

        let en_ContactInfo: ContactInfo_t = data.personalInfoData.contactInfo;
    
        return {
            en: {
                experience: en_Experience,
                education: en_Education,
                certifications: en_Certification,
                skills: en_Skills,
                projects: en_Projects,
                basicInfo: en_BasicInfo,
                professionalSummary: en_ProfessionalSummary,
                personalInterests: en_PersonalInterests,
                additionalInfo: en_AdditionalInfo,
                hobbies: en_Hobbies,
                contactInfo: en_ContactInfo // Add this line
            },
            cs: {
                experience: cs_Experience,
                education: cs_Education,
                certifications: cs_Certification,
                skills: cs_Skills,
                projects: cs_Projects,
                basicInfo: cs_BasicInfo,
                professionalSummary: cs_ProfessionalSummary,
                personalInterests: cs_PersonalInterests,
                additionalInfo: cs_AdditionalInfo,
                hobbies: cs_Hobbies,
                contactInfo: en_ContactInfo // Add this line
            }
        };
    }
    isLoading() {
        return this._isLoading;
    }
}