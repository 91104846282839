import React from 'react';
import { Card, CardContent, Typography, makeStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faLightbulb, faBullhorn, faHeart } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
    card: {
        margin: '20px 0',
        boxShadow: 'none',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '10px',
    },
});

const ProfessionalSummary: React.FC<{ summary: any, language: string }> = ({ summary, language }) => {
    const classes = useStyles();

    const translate = (text: { en: string, cs: string }) => {
        return text[language];
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} component="h2">
                    {translate({ en: 'Professional Summary', cs: 'Profesní shrnutí' })}
                </Typography>
                <List>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faBriefcase} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${translate({ en: 'Role', cs: 'Role' })}: ${summary.role}`} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faLightbulb} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${translate({ en: 'Skills', cs: 'Dovednosti' })}: ${summary.skills}`} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faBullhorn} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${translate({ en: 'Advocacy', cs: 'Obhajoba' })}: ${summary.advocacy}`} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faHeart} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${translate({ en: 'Interests', cs: 'Zájmy' })}: ${summary.interests}`} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default ProfessionalSummary;