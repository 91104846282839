import React, { useContext, useEffect, useState } from 'react';
import StateManager, { Information_t, LanguageContext } from '../modules/StateManager';

// Translation dictionary
const translations = {
    en: {
        loading: 'Loading...',
        experience: 'Experience',
        education: 'Education',
        skills: 'Skills',
        certifications: 'Certifications',
        hobbies: 'Hobbies',
        contactInformation: 'Contact Information',
        at: 'at',
        from: 'From',
        to: 'To',
        moreInfo: 'More Info',
        title: "{name}'s CV",
        email: 'Email',
        phone: 'Phone',
        linkedin: 'LinkedIn',
    },
    cs: {
        loading: 'Načítání...',
        experience: 'Zkušenosti',
        education: 'Vzdělání',
        skills: 'Dovednosti',
        certifications: 'Certifikace',
        hobbies: 'Koníčky',
        contactInformation: 'Kontaktní informace',
        at: 'u',
        from: 'Od',
        to: 'Do',
        moreInfo: 'Více informací',
        title: 'Životopis {name}',
        email: 'Email',
        phone: 'Telefon',
        linkedin: 'LinkedIn',
    },
};

const RobotPrint = () => {
    const { language } = useContext(LanguageContext);
    const [info, setInfo] = useState((StateManager.GetInstance().getInformation() as any) as Information_t);

    useEffect(() => {
        const stateManager = StateManager.GetInstance();
        const subscription = stateManager.getLanguage().subscribe(lang => {
            setInfo((stateManager.getInformation() as any) as Information_t);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    // Translation function
    const t = (key, variables = {}) => {
        const translation = translations[language][key];
        if (!translation) {
            console.warn(`Translation not found for key: ${key}`);
            return '';
        }

        // Replace variables in the translation
        return Object.keys(variables).reduce((translated, variable) => {
            return translated.replace(`{${variable}}`, variables[variable]);
        }, translation);
    };

    if (!info) {
        return <div>{t('loading')}</div>;
    }

    const renderExperience = (experience) => (
        <>
            <h2>{t('experience')}</h2>
            <ul>
                {experience.map((exp, index) => (
                    <li key={index}>
                        <div>{exp.title} {t('at')} {exp.company}</div>
                        <div>{exp.description}</div>
                        <div>{t('from')}: {exp.from} - {t('to')}: {exp.to}</div>
                    </li>
                ))}
            </ul>
        </>
    );

    const renderEducation = (education) => (
        <>
            <h2>{t('education')}</h2>
            <ul>
                {education.map((edu, index) => (
                    <li key={index}>
                        <div>{edu.title}</div>
                        <div>{edu.institution}</div>
                        <div>{edu.description}</div>
                        <div>{t('from')}: {edu.from} - {t('to')}: {edu.to}</div>
                        <div>{t('moreInfo')}: {edu.link}</div>
                    </li>
                ))}
            </ul>
        </>
    );

    const renderSkills = () => {
        const skillNames = [
            'Angular', 
            'Bootstrap', 
            'C (Programming Language)', 
            'C++', 
            'Git', 
            'HTML5', 
            'Java', 
            'JavaScript', 
            'Node.js', 
            'Python (Programming Language)', 
            'Raspberry Pi', 
            'React.js', 
            'TypeScript', 
            'Vue.js', 
            'Illustrator', 
            'Photoshop', 
            'Premiere Pro', 
            'AutoCAD', 
            'Webpack'
        ];
    
        return (
            <>
                <h2>{t('skills')}</h2>
                <ul>
                    {skillNames.map((skill, index) => (
                        <li key={index}>{skill}</li>
                    ))}
                </ul>
            </>
        );
    };

    const renderCertifications = (certifications) => (
        <>
            <h2>{t('certifications')}</h2>
            <ul>
                {certifications.map((cert, index) => (
                    <li key={index}>
                        <div>{cert.title}</div>
                        <div>{cert.organization}</div>
                        <div>{cert.description}</div>
                        <div>{t('from')}: {cert.from} - {t('to')}: {cert.to}</div>
                        <div>{t('moreInfo')}: {cert.link}</div>
                    </li>
                ))}
            </ul>
        </>
    );
    
    const renderHobbies = (hobbies) => (
        <>
            <h2>{t('hobbies')}</h2>
            <ul>
                {hobbies.map((hobby, index) => (
                    <li key={index}>
                        <div>{hobby.name}</div>
                        <div>{hobby.description}</div>
                        {hobby.link && <div>{t('moreInfo')}: {hobby.link}</div>}
                    </li>
                ))}
            </ul>
        </>
    );
    
    const renderContactInfo = (contactInfo) => (
        <>
            <h2>{t('contactInformation')}</h2>
            <div>Email: {contactInfo.email}</div>
            <div>{t('phone')}: {contactInfo.phone}</div>
            <div>LinkedIn: {contactInfo.linkedin}</div>
        </>
    );

    return (
        <div>
            <h1>{t('title', { name: info.basicInfo.name })}</h1>
            {renderExperience(info.experience)}
            {renderEducation(info.education)}
            {renderSkills()}
            {renderCertifications(info.certifications)}
            {renderHobbies(info.hobbies)}
            {renderContactInfo(info.contactInfo)}
        </div>
    );
};

export default RobotPrint;
