import React from 'react';
import PrintPersonalInfo from './print/PersonalInfo';
import PrintExperience from './print/Experience';
import PrintEducation from './print/Education';
import PrintCertifications from './print/Certifications';
import PrintHobbies from './print/Hobbies';

const PrintView: React.FC<{intendedTarget: string}> = (intendedTarget) => {
    return (
        <main className='wrapper'>
            <PrintPersonalInfo />
            <PrintExperience />
            <PrintEducation />
            <PrintCertifications />
            <PrintHobbies />
        </main>
    )
}

export default PrintView;