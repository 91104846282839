import React from "react";
import StateManager, { AdditionalInfo_t, BasicInfo_t, ProfessionalSummary_t } from "../../modules/StateManager";
import { faBirthdayCake, faPerson, faLocationDot, faGlobe } from "@fortawesome/free-solid-svg-icons";
import "./printStyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkillIcons from "./SkillIcons";
import ContactInfo from "./ContactInfo";

const PrintPersonalInfo: React.FC = () => {
    const personalInfo:BasicInfo_t = StateManager.GetInstance().getInformation()?.basicInfo as BasicInfo_t;
    const additionalInfo:AdditionalInfo_t = StateManager.GetInstance().getInformation()?.additionalInfo as AdditionalInfo_t;
    const professionalSummary:ProfessionalSummary_t = StateManager.GetInstance().getInformation()?.professionalSummary as ProfessionalSummary_t;

    return (
        <>
        <ContactInfo />
        <div className="pt-5 pb-3">
            <h1>{personalInfo.name}</h1>
            <p className="py-5 col-10 mx-auto" style={{textAlign: "justify", textAlignLast: "center"}}>{professionalSummary.summaryText}</p>

            <p className="d-flex pb-5">
                <span className="col-3 d-flex justify-content-center">
                    <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faPerson} />
                    <span className="fs-6">{personalInfo.pronouns}</span>
                </span>
                <span className="col-3 d-flex justify-content-center">
                    <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faBirthdayCake} />
                    <span className="fs-6">{personalInfo.dateOfBirth}</span>
                </span>
                <span className="col-3 d-flex justify-content-center">
                    <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faLocationDot} />
                    <span className="fs-6">{personalInfo.location}</span>
                </span>
                <span className="col-3 d-flex justify-content-center">
                    <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faGlobe} />
                    <span className="fs-6">CZ / EN</span>
                </span>
            </p>

            <blockquote className="philosophy">{additionalInfo.philosophy}</blockquote>
            <SkillIcons />
        </div>
        </>
    )
};

export default PrintPersonalInfo;