import StateManager from './StateManager';

export async function loadData() {
    const experienceData = await import('../assets/experienceData.json');
    const edCertData = await import('../assets/ed-cert.json');
    const personalInfoData = await import('../assets/personalInfo.json');
    const hobbiesData = await import('../assets/hobbies.json');
    

    const parsed =  StateManager.parseToInformation({
        personalInfoData,
        experienceData,
        edCertData,
        hobbiesData: {en: hobbiesData.en, cs: hobbiesData.cs}
    });

    StateManager.GetInstance().setInformation(parsed);
}