import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import StateManager from '../../modules/StateManager'; // Adjust the path as needed

const PrintHobbies = () => {
    const hobbies = StateManager.GetInstance().getInformation()?.hobbies as IHobby[];

    return (
        <div style={{pageBreakInside: 'avoid'}}>
            <h2>My Hobbies</h2>
            <Row className="hobbies-row">
                {hobbies.map((hobby, index) => (
                    <Col className='mb-4 hobby-col' key={index} sm={4} md={4}>
                        <Card className="styled-card mb-4">
                            <div className="image-container">
                                <img className="styled-img" src={hobby.images[0]?.src || "https://via.placeholder.com/150"} alt={hobby.images[0]?.description || "placeholder"} />
                            </div>
                            <Card.Body>
                                <Card.Title className="styled-title">{hobby.name}</Card.Title>
                                <Card.Text>{hobby.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default PrintHobbies;
