import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import './styles/index.scss';
import './styles/print.scss';
import App from './components/App';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { loadData } from './modules/loadData';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

loadData();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
