import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as BrandsIcons from '@fortawesome/free-brands-svg-icons';

const techToIcon = {
  'Angular': BrandsIcons.faAngular, 
  'Bootstrap': BrandsIcons.faBootstrap, 
  'C (Programming Language)': 'file:clang.svg', 
  'C++': 'file:cplusplus.svg', 
  'Git': BrandsIcons.faGit, 
  'HTML5': BrandsIcons.faHtml5, 
  'Java': BrandsIcons.faJava, 
  'JavaScript': BrandsIcons.faJsSquare, 
  'Node.js': BrandsIcons.faNodeJs, 
  'Python (Programming Language)': BrandsIcons.faPython, 
  'Raspberry Pi': BrandsIcons.faRaspberryPi, 
  'React.js': BrandsIcons.faReact, 
  'TypeScript': "file:typescript.svg",
  'Vue.js': BrandsIcons.faVuejs,
  'Illustrator': 'file:illustrator.svg', 
  'Photoshop': 'file:photoshop.svg', 
  'Premiere Pro': 'file:premiere-pro.svg', 
  'AutoCAD': 'file:autocad.svg', 
  'Webpack': 'file:webpack.svg'
};

const techColors = {
  'Angular': '#DD0031', 
  'Bootstrap': '#7952B3', 
  'Git': '#F05032', 
  'HTML5': '#E34F26', 
  'Java': '#007396', 
  'JavaScript': '#F7DF1E', 
  'Node.js': '#339933', 
  'Python (Programming Language)': '#3776AB', 
  'Raspberry Pi': '#C51A4A', 
  'React.js': '#61DAFB', 
  'Vue.js': '#4FC08D',
};
const SkillIcons = () => {
    return (
        <div className="pt-5 mx-auto" style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', maxWidth:'85%'}}>
            {Object.entries(techToIcon).map(([tech, icon], index) => {
                const color = techColors[tech] || 'black'; // Default color if not specified
                if (typeof icon === 'string' && icon.startsWith('file:') && icon.endsWith('.svg')) {
                    const svgPath = icon.replace('file:', '');
                    return <img key={index} src={svgPath} alt={tech} style={{margin: 8, height: '30px'}} />;
                }
                return icon ? <FontAwesomeIcon key={index} icon={icon as BrandsIcons.IconDefinition} style={{margin: 10, height: '32px', width: 'auto', color: color}} /> : null;
            })}
        </div>
    );
};


export default SkillIcons;