import React from 'react';
import { Card, CardContent, Typography, makeStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faChess, faPaintBrush, faHome } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
    card: {
        margin: '20px 0',
        boxShadow: 'none',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '10px',
    },
});

const PersonalInterests: React.FC<{ interests: any, language: string }> = ({ interests, language }) => {
    const classes = useStyles();

    const dictionary = {
        en: {
            "PersonalInterests": "Personal Interests",
            "Gaming": "Gaming",
            "Chess": "Chess",
            "OtherHobbies": "Other Hobbies",
            "HomeLife": "Home Life",
        },
        cs: {
            "PersonalInterests": "Osobní zájmy",
            "Gaming": "Hraní her",
            "Chess": "Šachy",
            "OtherHobbies": "Další koníčky",
            "HomeLife": "Domácí život",
        }   
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} component="h2">
                    {dictionary[language]["PersonalInterests"]}
                </Typography>
                <List>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faGamepad} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${dictionary[language]["Gaming"]}: ${interests.gaming.genre} - ${interests.gaming.types}`} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faChess} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${dictionary[language]["Chess"]}: ${interests.chess.note}`} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faPaintBrush} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${dictionary[language]["OtherHobbies"]}: ${interests.otherHobbies}`} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faHome} className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={`${dictionary[language]["HomeLife"]}: ${interests.homeLife.note}`} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default PersonalInterests;