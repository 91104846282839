// AdditionalInfo.tsx
import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';

import StateManager, { AdditionalInfo_t } from '../../modules/StateManager';

const useStyles = makeStyles({
    quote: {
        fontStyle: 'italic',
        padding: '1rem',
    },
});

const AdditionalInfo: React.FC = () => {
    const classes = useStyles();

    const additionalInfo = StateManager.GetInstance().getInformation()?.additionalInfo as AdditionalInfo_t;

    return (
        <Card>
            <CardContent>
                <Typography variant="body2" component="i" className={classes.quote}>
                    {additionalInfo.philosophy}            
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AdditionalInfo;