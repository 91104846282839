import React from 'react';
import StateManager from '../modules/StateManager';

const EducationCertification: React.FC<{language: string}> = ({language}) => {
  const information = StateManager.GetInstance().getInformation();
  const certifications = information?.certifications;
  const education = information?.education;

  const translate = (text: { en: string, cs: string }) => {
    return text[language];
  };

  if (!education || !certifications) {
    return <div>{translate({ en: 'Loading...', cs: 'Načítání...' })}</div>; // You can replace this with a loading indicator
  }

  return (
    <div className="container mt-4">
      <div className="accordion" id="educationCertificationAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="educationHeading">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#educationCollapse" aria-expanded="true" aria-controls="educationCollapse">
              {translate({ en: 'Education', cs: 'Vzdělání' })}
            </button>
          </h2>
          <div id="educationCollapse" className="accordion-collapse collapse show" aria-labelledby="educationHeading" data-bs-parent="#educationCertificationAccordion">
            <div className="accordion-body">
              {education.map((item, index) => (
                <div key={index} className="card mb-3">
                  <div className="card-body">
                    <i className={`fas ${item.icon} card-icon`}></i>
                    <h3 className="card-title">{item.title}</h3>
                    <p className="card-text">{item.description}</p>
                    <p className="card-text">{item.from} - {item.to}</p>
                    <a href={item.link} className="card-link">{translate({ en: 'More Info', cs: 'Více informací' })}</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="certificationsHeading">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#certificationsCollapse" aria-expanded="false" aria-controls="certificationsCollapse">
              {translate({ en: 'Certifications', cs: 'Certifikace' })}
            </button>
          </h2>
          <div id="certificationsCollapse" className="accordion-collapse collapse" aria-labelledby="certificationsHeading" data-bs-parent="#educationCertificationAccordion">
            <div className="accordion-body">
              {certifications.map((item, index) => (
                <div key={index} className="card mb-3">
                  <div className="card-body">
                    <i className={`fas ${item.icon} card-icon`}></i>
                    <h3 className="card-title">{item.title}</h3>
                    <p className="card-text">{item.description}</p>
                    <p className="card-text">{item.from} - {item.to}</p>
                    <a href={item.link} className="card-link">{translate({ en: 'More Info', cs: 'Více informací' })}</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationCertification;