import React from "react";
import StateManager, { CertificationItem_t, EducationItem_t, Information_t } from "../../modules/StateManager";
import "./printStyles.scss";

const PrintCertifications: React.FC = () => {

    const certificationItems = (StateManager.GetInstance().getInformation() as Information_t).certifications as CertificationItem_t[];
    return (
    <div style={{pageBreakInside: "avoid"}}>
        <h2 className="mb-4 pt-5">Certifications</h2>
        <section className="row">
            {
                certificationItems.map((certification, index) => (
                    <div key={index} className={"col mb-3 card-container"}>
                        <div className="card fixed-card p-3 flex-grow-1 d-flex flex-column" style={{fontSize :"11px"}}>
                            <div className="card-body">
                                <h3 className="card-title mb-2">{certification.title}</h3>
                                <h4 className="card-subtitle mb-2 text-muted">{certification.from} - {certification.to}</h4>
                                <h4 className="card-subtitle mb-2 text-muted">{certification.organization}</h4>
                                <p className="card-text">{certification.description}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </section>
    </div>);
}

export default PrintCertifications;