import React from "react";
import StateManager, { EducationItem_t, Information_t } from "../../modules/StateManager";
import "./printStyles.scss";

const PrintEducation: React.FC = () => {

    const educationItems = (StateManager.GetInstance().getInformation() as Information_t).education as EducationItem_t[];
    return (
    <>
        <h2 className="mb-4 pt-5">Education</h2>
        <section className="row">
            {
                educationItems.map((education, index) => (
                    <div key={index} className={"col mb-3 card-container"}>
                        <div className="card fixed-card p-3 flex-grow-1 d-flex flex-column" style={{fontSize :"11px"}}>
                            <div className="card-body">
                                <h3 className="card-title mb-2">{education.title}</h3>
                                <h4 className="card-subtitle mb-2 text-muted">{education.from} - {education.to}</h4>
                                <h4 className="card-subtitle mb-2 text-muted">{education.institution}</h4>
                                <p className="card-text">{education.description}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </section>
    </>);
}

export default PrintEducation;