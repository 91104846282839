import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Tabs, Tab, Menu, MenuItem, Fab, Zoom } from '@material-ui/core';
import Experience from './Experience';
import PersonalInfo from './PersonalInfo';
import EducationCertification from './EducationCertification';
import PrintView from './PrintView';
import RobotPrint from './RobotPrint';
import StateManager, { LanguageContext } from '../modules/StateManager';
import '../styles/App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

const App = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [language, setLanguage] = useState("en");
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isPrintProcessActive, setIsPrintProcessActive] = useState(false);
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(true);

  useEffect(() => {
    const langSub: Subscription = StateManager.GetInstance().getLanguage().subscribe(setLanguage);
    return () => langSub.unsubscribe();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsPrintButtonDisabled(false), 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
        initiatePrint('Print for HR');
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const initiatePrint = (printFor) => {
    if (isPrintProcessActive) return;
    setIsPrintProcessActive(true);

    const Component = printFor === 'Print for HR' ? PrintView : RobotPrint;
    printDocument(Component);
  };

  const printDocument = (Component) => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      console.error('Failed to open new window. Please check if popup blocker is enabled.');
      return;
    }

    printWindow.document.body.innerHTML = '<div id="print-root"></div>';
    ReactDOM.render(<Component />, printWindow.document.getElementById('print-root'));

    copyStylesToPrintWindow(printWindow);

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
      setIsPrintProcessActive(false);
    }, 1000); // Adjust delay as needed
  };

  const copyStylesToPrintWindow = (printWindow) => {
    const styles = document.querySelectorAll('style, link[rel="stylesheet"]');
    styles.forEach(style => printWindow.document.head.appendChild(style.cloneNode(true)));
  };

  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);

  const dictionary = {
    en: {
      'PersonalInfo': 'Personal Info',
      'Experience': 'Experience',
      'EducationCertification': 'Education & Certification',
      'SwitchLanguage': 'Změnit jazyk [CS]',
    },
    cs: {
      'PersonalInfo': 'Osobní údaje',
      'Experience': 'Zkušenosti',
      'EducationCertification': 'Vzdělání & Certifikace',
      'SwitchLanguage': 'Switch Language [EN]',
    },
  };

  const handleTabChange = (event, newValue) => {
    // Check if the clicked tab is the language switch tab
    const isLanguageTab = event.currentTarget.getAttribute('aria-label') === dictionary[language]["SwitchLanguage"];
  
    if (isLanguageTab) {
      // Prevent tab change and switch language
      StateManager.GetInstance().switchLanguage();
    } else {
      // Change to the selected tab
      setSelectedTab(newValue);
    }
  };

  return (
    <LanguageContext.Provider value={{ language }}>
    <div className='container-fluid'>
      <div className="non-print-content">
        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label={dictionary[language]["PersonalInfo"]} />
          <Tab label={dictionary[language]["Experience"]} />
          <Tab label={dictionary[language]["EducationCertification"]} />
          <Tab label={dictionary[language]["SwitchLanguage"]} aria-label={dictionary[language]["SwitchLanguage"]} />
        </Tabs>
        {selectedTab === 0 && <PersonalInfo language={language} />}
        {selectedTab === 1 && <Experience language={language} />}
        {selectedTab === 2 && <EducationCertification language={language} />}
      </div>
        <Zoom in={true}>
          <Fab disabled={isPrintButtonDisabled} color="primary" aria-label="print" style={{ position: 'fixed', bottom: '20px', right: '20px' }} onClick={handleMenuOpen}>
            <FontAwesomeIcon icon={faPrint} />
          </Fab>
        </Zoom>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleMenuClose();
            initiatePrint('Print for HR');
          }}>
            Print for HR
          </MenuItem>
          <MenuItem onClick={() => {
            handleMenuClose();
            initiatePrint('Print for AI');
          }}>
            Print for AI
          </MenuItem>
        </Menu>
      </div>
    </LanguageContext.Provider>
  );
};

export default App;
