export default function Singleton<T extends { new (...args: any[]): {} }>(constructor: T) {
    const instanceKey = Symbol('instance');

    return class extends constructor {
        private constructor(...args: any[]) {
            super(...args);
        }
        public static GetInstance(this: T & { [instanceKey]: InstanceType<T> }, ...args: any[]): InstanceType<T> {
            if (!this[instanceKey]) {
                this[instanceKey] = new this(...args) as InstanceType<T>; // Cast the instance to InstanceType<T>
            }
            return this[instanceKey];
        }
    } as T & { new (...args: any[]): InstanceType<T>; GetInstance(...args: any[]): InstanceType<T> };
}


export interface SingletonConstructor {
    new (...args: any[]): ISingleton;
    GetInstance(): ISingleton;
}
export interface ISingleton {
    
}