import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import StateManager from "../../modules/StateManager"; // Import the IContactInfo type

const ContactInfo: React.FC =  () => {
    const contactInfo: IContactInfo = StateManager.GetInstance().getInformation()?.contactInfo as IContactInfo;
    return (
        <div className="row justify-content-center pb-5 print-footer">
            <div className="col-4 d-flex justify-content-center">
                <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faEnvelope} />
                <span className="fs-6">{contactInfo.email}</span>
            </div>
            <div className="col-4 d-flex justify-content-center">
                <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faPhone} />
                <span className="fs-6">{contactInfo.phone}</span>
            </div>
            <div className="col-4 d-flex justify-content-center">
                <FontAwesomeIcon className="fs-5 px-3 text-center" icon={faLinkedin} />
                <span className="fs-6">@{contactInfo.linkedin.split('/').slice(-2)[0]}</span>
            </div>
        </div>
    );
};
export default ContactInfo;