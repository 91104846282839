// PersonalInfo.tsx
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import StateManager, { Information_t } from '../modules/StateManager';
import BasicInfo from './personal/BasicInfo';
import ProfessionalSummary from './personal/ProfessionalSummary';
import PersonalInterests from './personal/PersonalInterests';
import AdditionalInfo from './personal/AdditionalInfo';

const PersonalInfo: React.FC<{language: string}> = ({language}) => {
  const stateManager = StateManager.GetInstance();

  const [isLoading, setIsLoading] = useState(stateManager.isLoading());
  const [value, setValue] = useState(0);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const checkLoading = setInterval(() => {
      setIsLoading(stateManager.isLoading());
    }, 1000);

    return () => clearInterval(checkLoading);
  }, []);

  if(isLoading) {
    return <div>Loading...</div>;
  }

  const information = stateManager.getInformation() as Information_t;

  const dictionary = {
    en: {
      "ProfessionalSummary": "Professional Summary",
      "PersonalInterests": "Personal Interests",
    },
    cs: {
      "ProfessionalSummary": "Profesní shrnutí",
      "PersonalInterests": "Zájmy",
    }
  }

  return (
    <div>
      <BasicInfo info={information.basicInfo} language={language} />
      <AdditionalInfo />
      <Tabs value={value} onChange={handleChange}> 
        <Tab label={dictionary[language]['ProfessionalSummary']} />
        <Tab label={dictionary[language]['PersonalInterests']} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProfessionalSummary summary={information.professionalSummary} language={language} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PersonalInterests interests={information.personalInterests} language={language}  />
      </TabPanel>
    </div>
  );
};

function TabPanel(props: { children?: React.ReactNode; index: any; value: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default PersonalInfo;