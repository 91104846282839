import React from 'react';
import StateManager from '../modules/StateManager';

const Experience: React.FC<{language: string}> = ({language}) => {
  const information = StateManager.GetInstance().getInformation();
  const experience = information?.experience;

  if (!experience) {
    return <div>Loading...</div>; // You can replace this with a loading indicator
  }

  return (
    <div className="container mt-4">
      <div className="accordion" id="experienceAccordion">
        {experience.map((item, index) => (
          <div key={index} className="accordion-item">
            <h2 className="accordion-header" id={`heading${index}`}>
              <button className={`accordion-button ${index !== 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0} aria-controls={`collapse${index}`}>
                {item.company}
              </button>
            </h2>
            <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#experienceAccordion">
              <div className="accordion-body">
                <h3 className="card-title">{item.title}</h3>
                <h4 className="card-subtitle mb-2 text-muted">{item.company}</h4>
                <p className="card-text">{item.description}</p>
                <p className="card-text">{item.from} - {item.to}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
