import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBirthdayCake, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        margin: '20px 0 0px 0', // This reduces the bottom margin
        boxShadow: 'none', // This removes the shadow
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    info: {
        fontSize: 18,
        color: '#333',
        display: 'flex',
        alignItems: 'center',
    },
    'p-right-1': {
        paddingRight: '1rem',
    },
    'px-1': {
        padding: '0 1rem',
    },
    'p-left-1': {
        paddingLeft: '1rem',
    },
});

const BasicInfo: React.FC<{ info: any, language: string }> = ({ info, language }) => {
    const classes = useStyles();

    const translate = (text: { en: string, cs: string }) => {
        return text[language];
    };

    // Parse the date of birth
    const [day, month, year] = info.dateOfBirth.split('-').map(Number);
    const dateOfBirth = new Date(year, month - 1, day);

    // Convert the date of birth to a localized string
    const localizedDateOfBirth = dateOfBirth.toLocaleDateString(language === 'en' ? 'en-US' : 'cs-CZ');

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} component="h2">
                    {info.name}
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item className={classes.info}>
                        <FontAwesomeIcon icon={faUser} className={classes['p-right-1']} />
                        {info.pronouns}
                    </Grid>
                    <Grid item className={classes.info}>
                        <FontAwesomeIcon icon={faBirthdayCake} className={classes['px-1']} />
                        {localizedDateOfBirth}
                    </Grid>
                    <Grid item className={classes.info}>
                        <FontAwesomeIcon className={classes['p-right-1']} icon={faMapMarkerAlt} />
                        {translate({ en: info.location, cs: info.location })}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BasicInfo;