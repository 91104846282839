import React, { useState } from "react";
import StateManager from "../../modules/StateManager";
import "./printStyles.scss";

const PrintExperience: React.FC = () => {
    const experiences = StateManager.GetInstance().getInformation()?.experience;
    const [loadedImages, setLoadedImages] = useState<Array<string>>([]);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.target as HTMLImageElement;
        if (!loadedImages.includes(target.src)) {
            setLoadedImages(prevState => [...prevState, target.src]);
        }
    };

        return (
            <div className="pt-5 pb-3 w-90 mx-auto" style={{pageBreakInside: 'avoid'}}>
                <h2>Experience</h2>
                <div className="row">
                    {experiences?.map((experience, index) => (
                        <div key={index} style={{pageBreakInside: 'avoid'}} className="col mb-3 card-container">
                            <div className="card fixed-card">
                                <div className="card-body">
                                    <div className="logo-title">
                                        <img onLoad={handleImageLoad} style={{filter: experience.logo.endsWith('.png') ? 'invert(1)' : ''}} src={experience.logo} alt={experience.company} />
                                        <h3 className="fw-bold">
                                            {experience.company}
                                        </h3>
                                    </div>
                                    <h4>
                                        {experience.title} ({experience.from} - {experience.to})
                                    </h4>
                                    <p>
                                        {experience.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
};

export default PrintExperience;